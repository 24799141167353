import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { PageLayout, LegalMenu } from '../../components'

const LegalIndexPage = ({ location }) => {
  const categories = ["Legal", "Security"]
  const data = useStaticQuery(graphql`
    query LegalQuery {
      postItems: allMarkdownRemark(filter: {frontmatter: {category: {in: ["Legal", "Security"]}}}, sort: {fields: frontmatter___order}) {
        posts: edges {
          post: node {
            frontmatter {
              title
              category
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const dataItems = (category) => {
    const { posts } = data.postItems
    return posts && posts.filter(item => item.post.frontmatter.category === category)
  }

  return (
    <PageLayout 
      location={location}
      title="Legal & Security"
      description="Hippobyte Legal and Security Agreements, Policies and Guidelines."
      pageTheme="dark"
      pageHeading={{
        title: {
          label: "Legal & Security"
        }
      }}
      footer={true}
    >
      {
        categories.map(category => <LegalMenu title={category} data={(dataItems(category))} />)
      }
    </PageLayout>
  )
}

export default LegalIndexPage